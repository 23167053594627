<template v-slot:extension>
  <v-btn small outlined color="secondary" depressed @click="updateBannerStates()" v-if="superAdmin">
    <v-icon left> mdi-refresh </v-icon>
    Refresh Status
  </v-btn>
</template>
<script>
export default {
  computed: {
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
  },
  methods: {
    async updateBannerStates() {
      try {
        const action = { action: "update_banner_status" };
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/updateBannerStates", action);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Updating Banner States...",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title: error.message,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
};
</script>
