<template>
  <div>
    <ConfirmDelete
      :dialog="dialog"
      :item="creativeDetails"
      @delete="rundeleteFn($event)"
      @dismiss="dismissFn($event)"
    />

    <modalComponent
      :dialog="modalShow"
      :item="creativeDetails"
      title="Submit Banner"
      content="Are you sure you want to submit this banner?"
      @action="submitBannerEvent($event)"
      @dismiss="dismissFn($event)"
    />

    <v-card flat>
      <v-card-title class="d-flex align-center">
        <v-row align="center" no-gutters>
          <v-col class="d-flex align-center" cols="3">
            <v-btn depressed color="primary" :to="routeRedirect"
              ><v-icon left> mdi-plus </v-icon>Add Banner</v-btn
            >

            <v-btn
              :disabled="loading"
              @click="submitMultipleBanner"
              class="ml-2"
              v-if="selected.length && superAdmin"
              depressed
              color="secondary"
            >
              <v-icon left> mdi-dots-horizontal </v-icon>
              ({{ selected.length }}) Submit Banners
            </v-btn>
          </v-col>
          <v-col cols="9">
            <v-row align="center" justify="end" dense>
              <v-col cols="3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        :items="creatives"
        :items-per-page="itemsPerPage"
        v-model="selected"
        :loading="loading"
        :show-select="superAdmin"
        loading-text="Loading... Please wait"
        hide-default-footer
        v-if="creatives"
        no-data-text="No Creatives Available"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:item.banner_id="{ item }">
          <span>{{ item.banner_id }}</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.name="{ item }">
          <span
            class="font-weight-bold bannerid primary--text"
            @click="showBanner(item)"
            >{{ item.banner_name }}</span
          >
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.campaign="{ item }">
          {{ item.campaign_name }}
        </template>

        <template #[`item.banner_objective`]="{ item }">
          <span class="text-capitalize">{{
            item.campaign_objective ? item.campaign_objective : "-"
          }}</span>
        </template>
        <template #[`item.banner_type`]="{ item }">
          <span class="text-capitalize">{{
            item.campaign_type ? item.campaign_type : "-"
          }}</span>
        </template>
        <template #[`item.banner_click_effect`]="{ item }">
          <span class="text-capitalize">{{
            item.click_effect_type ? item.click_effect_type : "-"
          }}</span>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.banner_status="{ item }">
          <v-chip
            v-if="isActive(item)"
            color="green lighten-5"
            text-color="success"
            class="font-weight-bold"
            small
          >
            <v-icon x-small left>mdi-circle</v-icon>Active
          </v-chip>
          <v-chip
            v-else
            color="error lighten-5"
            text-color="error"
            class="font-weight-bold"
            small
          >
            <v-icon x-small left>mdi-circle</v-icon>Paused
          </v-chip>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.status="{ item }">
          <!-- <span :class="[isSubmitted(item) ? 'success--text' : 'error--text']">
            {{ isSubmitted(item) ? "Submitted" : "Not Submitted" }}</span
          > -->
          <v-chip
            v-if="item.submission_status"
            color="green lighten-5"
            text-color="success"
            class="font-weight-bold"
            small
          >
            Yes
          </v-chip>
          <v-chip
            v-else
            color="orange lighten-5"
            text-color="warning"
            class="font-weight-bold"
            small
          >
            No
          </v-chip>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.submit_status="{ item }">
          <div
            v-if="
              item.approval_status === 'DISAPPROVED' ||
              item.approval_status === 'AWAITING_RESUBMISSION'
            "
          >
            <v-btn
              @click="resubmitBanner(item.banner_id)"
              :color="submitButtonColor(item)"
              small
              :disabled="item.approval_status === 'APPROVED'"
              depressed
            >
              {{ "Resubmit Banner" }}
            </v-btn>
          </div>
          <div v-else>
            <v-btn
              @click="submitBanner(item)"
              :color="submitButtonColor(item)"
              small
              :disabled="item.approval_status === 'APPROVED'"
              depressed
            >
              {{ "Submit Banner" }}
            </v-btn>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.approval="{ item }">
          <v-chip
            v-if="item.approval_status == 'APPROVED'"
            color="green lighten-5"
            text-color="success"
            class="font-weight-bold"
            small
          >
            Approved
          </v-chip>

          <v-chip
            v-else-if="item.approval_status == 'AWAITING_APPROVAL'"
            color="orange lighten-5"
            text-color="warning"
            class="font-weight-bold"
            small
          >
            Awaiting Approval
          </v-chip>
          <v-chip
            v-else-if="item.approval_status == 'AWAITING_RESUBMISSION'"
            color="orange lighten-5"
            text-color="warning"
            class="font-weight-bold"
            small
          >
            Awaiting Resubmission
          </v-chip>
          <v-chip
            v-else-if="item.approval_status == 'AWAITING_SUBMISSION'"
            color="grey lighten-5"
            text-color="grey"
            class="font-weight-bold"
            small
          >
            Awaiting Submission
          </v-chip>

          <v-chip
            v-else
            color="error lighten-5"
            text-color="error"
            class="font-weight-bold"
            small
          >
            Disapproved
          </v-chip>
        </template>
        <!-- action -->
        <template #[`item.action`]="{ item }">
          <v-menu left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="runPauseBanner(item)">
                <v-list-item-title
                  ><v-icon fab small class="me-2">mdi-pause</v-icon>
                  {{ isActive(item) ? "Pause Banner" : "Resume Banner" }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="copyBannerUrl(item)">
                <v-list-item-title
                  ><v-icon fab small class="me-2">mdi-content-copy</v-icon>Copy
                  Banner URL</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                @click="
                  $router.push({
                    name: 'edit-programmatic-banner',
                    params: { id: item.banner_id },
                  })
                "
              >
                <v-list-item-title
                  ><v-icon small class="me-2">mdi-image-edit</v-icon> Edit
                  Banner</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="submitBanner(item)">
                <v-list-item-title
                  ><v-icon small class="me-2">mdi-redo-variant</v-icon> Submit
                  Banner</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="resubmitBanner(item.id)">
                <v-list-item-title
                  ><v-icon small class="me-2">mdi-redo-variant</v-icon> Resubmit
                  Banner</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="openModal(item)">
                <v-list-item-title
                  ><v-icon small color="error" class="me-2">mdi-delete</v-icon>
                  Delete Banner</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <!-- eslint-disable-next-line -->
      </v-data-table>
      <v-divider></v-divider>
      <v-card-actions class="d-flex align-center pa-4" v-if="creatives">
        <div class="d-flex align-center">
          <div class="caption mr-3">
            Page: {{ pagination.page }} of {{ pagination.totalItems }}
          </div>

          <v-select
            v-model="filter_params.per_page"
            outlined
            :items="items"
            label="Rows Per Page"
            dense
            hide-details
          ></v-select>
        </div>

        <v-spacer></v-spacer>

        <v-pagination
          v-model="pagination.page"
          :length="pagination.totalItems"
          :total-visible="10"
          color="primary"
          class="custom"
          @input="handlePageChange"
        ></v-pagination>
      </v-card-actions>
    </v-card>
    <!-- action log -->

    <v-navigation-drawer v-model="drawer" fixed temporary right width="50%">
      <v-card max-height="100%" flat v-if="fetchCreativeLoader">
        <v-card-title class="py-2 pr-3 justify-space-between">
          <h6 class="text-uppercase">
            Creative ID: {{ creativeDetails.name }}
          </h6>
          <v-btn icon @click="drawer = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="text-center">
          <v-progress-circular
            :indeterminate="fetchCreativeLoader"
            color="primary"
          ></v-progress-circular>
        </v-card-text>
      </v-card>

      <v-card v-else flat>
        <v-card-title class="py-2 pr-3 justify-space-between">
          <h6 class="text-uppercase">
            Creative ID: {{ creativeDetails.name }}
          </h6>
          <v-btn icon @click="drawer = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-expansion-panels accordion flat v-model="creativeExpansion" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h4>Creative Details</h4>
            </v-expansion-panel-header>

            <!-- <v-divider></v-divider> -->
            <v-expansion-panel-content>
              <v-row no-gutters>
                <v-col cols="3">
                  <h5>Campaign</h5>
                </v-col>
                <v-col>
                  <p class="text-body-2">
                    {{ creativeDetails?.campaign }}
                  </p>
                </v-col>
              </v-row>

              <v-row no-gutters
                ><v-col cols="3">
                  <h5>Banner Objective</h5>
                </v-col>
                <v-col>
                  <p class="text-body-2 text-capitalize">
                    {{ creativeDetails?.banner_objective }}
                  </p>
                </v-col>
              </v-row>

              <v-row no-gutters
                ><v-col cols="3">
                  <h5>Size</h5>
                </v-col>
                <v-col>
                  <p class="text-body-2">
                    {{ creativeDetails?.width }} x {{ creativeDetails?.height }}
                  </p>
                </v-col>
              </v-row>

              <v-row no-gutters
                ><v-col cols="3">
                  <h5>Banner Type</h5>
                </v-col>
                <v-col>
                  <p class="text-body-2 text-capitalize">
                    {{ creativeDetails?.banner_type }}
                  </p>
                </v-col>
              </v-row>

              <v-row no-gutters
                ><v-col cols="3">
                  <h5>Banner Objective</h5>
                </v-col>
                <v-col>
                  <p class="text-body-2 text-capitalize">
                    {{ creativeDetails?.banner_objective ?? "N/A" }}
                  </p>
                </v-col>
              </v-row>

              <v-row no-gutters
                ><v-col cols="3">
                  <h5>Click-through URLs</h5>
                </v-col>
                <v-col>
                  <p class="text-body-2">
                    <a :href="creativeDetails.click_through_url">{{
                      creativeDetails.click_through_url ?? "N/A"
                    }}</a>
                  </p>
                </v-col>
              </v-row>

              <v-row no-gutters
                ><v-col cols="3">
                  <h5>Conversion Type</h5>
                </v-col>
                <v-col>
                  <p class="text-body-2 text-capitalize">
                    {{ singlecreative?.campaign?.conversion_type ?? "N/A" }}
                  </p>
                </v-col>
              </v-row>

              <v-row no-gutters
                ><v-col cols="3">
                  <h5>Start & End Date</h5>
                </v-col>
                <v-col>
                  <p class="text-body-2 text-capitalize">
                    {{
                      formatDateWithoutTime(
                        singlecreative?.campaign?.activate_time
                      ) ?? "N/A"
                    }}
                    -
                    {{
                      formatDateWithoutTime(
                        singlecreative?.campaign?.expire_time
                      ) ?? "N/A"
                    }}
                  </p>
                </v-col>
              </v-row>

              <v-row no-gutters
                ><v-col cols="3">
                  <h5>Total Budget</h5>
                </v-col>
                <v-col>
                  <p class="text-body-2 text-capitalize">
                    $
                    {{
                      singlecreative && singlecreative?.total_budget
                        ? parseFloat(singlecreative?.total_budget).toFixed(2)
                        : "N/A"
                    }}
                  </p>
                </v-col>
              </v-row>

              <v-row no-gutters
                ><v-col cols="3">
                  <h5>Interstitial</h5>
                </v-col>
                <v-col>
                  <p class="text-body-2 text-capitalize">
                    {{
                      singlecreative?.interstitial === 1
                        ? "Yes"
                        : "N/A" ?? "N/A"
                    }}
                  </p>
                </v-col>
              </v-row>

              <v-row no-gutters
                ><v-col cols="3">
                  <h5>Banner Click Effect</h5>
                </v-col>
                <v-col>
                  <p class="text-body-2 text-capitalize">
                    {{
                      singlecreative?.banner_attribute?.click_effect_type ??
                      "N/A"
                    }}
                  </p>
                </v-col>
              </v-row>

              <v-row no-gutters
                ><v-col cols="3">
                  <h5>Restricted Category</h5>
                </v-col>
                <v-col>
                  <p class="text-body-2 text-capitalize">
                    {{
                      singlecreative?.banner_attribute
                        ?.declared_restricted_categories ?? "N/A"
                    }}
                  </p>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
            <v-divider></v-divider>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h4>Creative Preview</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div style="position: relative">
                <iframe
                  :src="creativeDetails.imageurl"
                  :width="creativeDetails.width"
                  :height="creativeDetails.height"
                  frameborder="0"
                  class="grey lighten-5"
                ></iframe
                ><a
                  :href="creativeDetails.click_through_url"
                  :style="{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: 'inline-block',
                    width: creativeDetails.width + 'px',
                    height: creativeDetails.height + 'px',
                    zIndex: 5,
                  }"
                ></a>
              </div>

              <!-- <span v-html="creativeDetails.html_template"></span> -->
            </v-expansion-panel-content>
            <v-divider></v-divider>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import ConfirmDelete from "../ConfirmDelete.vue";
import modalComponent from "../modalComponent.vue";
export default {
  data() {
    return {
      menu: [
        { title: "Download", icon: "mdi-download" },
        { title: "Duplicate", icon: "mdi-content-copy" },
        { title: "Print", icon: "mdi-printer" },
        { title: "Delete", icon: "mdi-delete" },
      ],
      selected: [],
      fetchCreativeLoader: false,
      dialog: false,
      width: "unset",
      bannerShow: false,
      drawer: false,
      modalShow: false,
      id: null,
      creativeDetails: {},
      creativeExpansion: [0, 1],
      campaigns: [],
      itemsPerPage: 10,
      items: [10, 25, 50, 200, 250],
      selected_agency: null,
      selected_advertiser: null,
      selected_campaign: null,
      fetched_advertisers: [],
      fetched_campaigns: [],
      creatives: [],
      filtered_creatives: [],
      search:"",
      filter_params: {
        per_page: 10,
        page: 1,
        search: "",
      },
      pagination: {
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
      },
    };
  },
  components: {
    ConfirmDelete,
    modalComponent,
  },
  props: {
    data: Object,
    headers: Array,
    all_campaigns: Array,
    agencies: Array,
    advertisers: Array,
    loading: Boolean,
    banner: {
      type: Object,
    },
  },
  watch: {
    data() {
      this.creatives = this.data.data;
      this.pagination.page = this.data.current_page;
      this.pagination.totalItems = this.data.last_page;
      this.pagination.rowsPerPage = this.data.per_page;
    },
    search() {
      this.filter_params.search = this.search;
      this.filter_params.page = 1;
      this.$emit("filterParams", this.filter_params);
    },
    all_campaigns() {
      this.campaigns = this.all_campaigns;
    },
    selected_agency() {
      this.fetched_advertisers = this.selected_agency
        ? this.selected_agency.client
        : [];
    },
    async selected_advertiser() {
      this.fetched_campaigns = await this.campaigns.filter((campaign) =>
        campaign.campaign_attribute
          ? campaign.campaign_attribute.client_id ===
            this.selected_advertiser.id
          : []
      );
    },

    async selected_campaign() {
      this.filtered_creatives = await this.creatives.filter(
        (creative) => creative.campaign_id === this.selected_campaign
      );
    },

    filter_params: {
      handler: function () {
        this.itemsPerPage = this.filter_params.per_page;
        this.filter_params.search = this.search;

        this.$emit("filterParams", this.filter_params);
      },
      deep: true,
    },
  },
  computed: {
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
    routeRedirect() {
      if (this.$route.name == "truecaller-creatives") {
        return "/truecaller/add-banner";
      }
      if (this.$route.name == "opera-creatives") {
        return "/opera/add-banner";
      }
      if (this.$route.name == "phoenix-creatives") {
        return "/phoenix/add-banner";
      }
      if (this.$route.name == "transsion-creatives") {
        return "/transsion/add-banner";
      }
      if (this.$route.name == "ctv-creatives") {
        return "/ctv/add-banner";
      }
      if (this.$route.name == "gaming-creatives") {
        return "/gaming/add-banner";
      } else {
        return "/tradedesk/add-banner";
      }
    },
    singlecreative() {
      return this.$store.state.dsp.dsp_creative;
    },
  },
  methods: {
    submitMultipleBanner() {
      //get banner ids
      let ids = this.selected.map((item) => item.id);

      this.$emit("submitMultipleBanner", ids);
    },
    formatDateWithoutTime(dateString) {
      if (dateString === null) {
        return "-";
      }
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      const formattedDate = new Date(dateString).toLocaleDateString(
        undefined,
        options
      );
      return formattedDate;
    },
    runPauseBanner(item) {
      this.$emit("pauseBanner", item.banner_id);
    },
    isApproved(item) {
      if (item.submission_status == null) {
        return "pending";
      } else if (!item.status) {
        return "pending resubmission";
      } else if (
        item.submission_status != null &&
        item.submission_status.network_policy_compliance_status ===
          "APPROVED" &&
        item.submission_status.platform_policy_compliance_status === "APPROVED"
      ) {
        return "approved";
      } else if (
        item.submission_status != null &&
        item.submission_status.network_policy_compliance_status ===
          "PENDING_REVIEW" &&
        item.submission_status.platform_policy_compliance_status ===
          "PENDING_REVIEW"
      ) {
        return "pending approval";
      } else if (
        item.submission_status != null &&
        item.submission_status.network_policy_compliance_status === "PENDING" &&
        item.submission_status.platform_policy_compliance_status === "PENDING"
      ) {
        return "pending approval";
      } else {
        return "disapproved";
      }
    },
    isSubmitted(item) {
      return item.status;
    },
    isActive(item) {
      let bid_ecpm = item.bid_ecpm ? Number(item.bid_ecpm) : 0;

      return bid_ecpm === 0 ? false : true;
    },
    handlePageChange(value) {
      this.pagination.page = value;
      this.filter_params.page = value;
      this.$emit("triggerPageChange", value);
    },
    submitButtonColor(status) {
      if (status.submission_status == null) {
        return "success";
      } else {
        return "warning";
      }
    },
    resetFilters() {
      this.selected_agency = null;
      this.selected_advertiser = null;
      this.selected_campaign = null;
    },
    rundeleteFn(id) {
      // Delete campaign
      this.$emit("deleteCreative", id);

      this.dialog = false;
    },
    dismissFn() {
      this.dialog = false;
      this.bannerShow = false;
      this.modalShow = false;
    },
    async openModal(item) {
      this.dialog = !this.dialog;
      this.creativeDetails = await item;
    },
    async submitBanner(item) {
      this.modalShow = !this.modalShow;
      this.creativeDetails = await item;
    },
    async showBanner(item) {
      //fetch creative by ID
      this.fetchCreativeByID(item.id);

      this.drawer = !this.drawer;
      this.creativeExpansion = [0, 1];
      this.creativeDetails = await item;
    },
    async copyBannerUrl(item) {
      try {
        await navigator.clipboard.writeText(item.imageurl);
        alert("Banner copied url successfuly");
      } catch ($e) {
        alert("An error occured while copying");
      }
    },
    submitBannerEvent(item) {
      if (item.submission_status == null) {
        this.$emit("submitBanner", item.banner_id);
      } else {
        this.$emit("reSubmitBanner", item.banner_id);
      }

      this.modalShow = false;
    },
    resubmitBanner(id) {
      this.$emit("reSubmitBanner", id);
    },

    async fetchCreativeByID(id) {
      this.fetchCreativeLoader = true;

      try {
        await this.$store.dispatch("dsp/getSingleCreative", id);
      } catch (error) {
        console.log(error);
      } finally {
        this.fetchCreativeLoader = false;
      }
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.bannerid {
  cursor: pointer;
}
</style>
