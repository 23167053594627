<template>
  <v-dialog v-model="dialog" width="500" light overlay-opacity="0.5" persistent>
    <v-card>
      <v-card-title class="text-h6 py-2 primary white--text">
        {{ title }}
      </v-card-title>

      <v-card-text class="pt-5">
        {{ content }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="py-3">
        <v-spacer></v-spacer>
        <v-btn text @click="dismiss"> Close </v-btn>
        <v-btn color="primary" depressed @click="confirm"> Confirm </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    confirm() {
      this.$emit("action", this.item);
    },
    dismiss() {
      this.$emit("dismiss");
    },
  },
};
</script>

<style scoped>
.v-dialog {
  box-shadow: none !important;
}
</style>
